
/* eslint-disable */

const home ={
        state: {
            titleId:0,
        },
        mutations: {
            SET_TITLE:(state,val)=>{
                state.titleId =val
                localStorage.setItem('titleId',val)
            },
        },
        actions: {
            
        }

}

 export default home