<template>
    <div class="container">
        <div class="carouselDiagram">
            <el-carousel :interval="3000" height="700px">
                <!-- <el-carousel-item v-for="(item,index) in imaginList" :key="index">
            <img :src="item.url" :alt="item.title" style="width:100%;height:100%;">
          </el-carousel-item> -->
                <el-carousel-item>
                    <div class="lunboBox box1">
                        <div class="wrapper">
                            <div class="lunboBox_right">
                                <div class="right_01">检测产业互联网</div>
                                <div class="right_02">检验检测生态链</div>
                                <!-- <div class="right_02_02">检验检测生态链</div> -->
                                <div class="right_03">
                                    <span>了解更多</span>
                                </div>
                            </div>
                            <div class="lunboBox_left">
                                <div>
                                    <!-- <img src="../../assets/gongsi/图1.png" alt="" /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>

                <el-carousel-item>
                    <div class="lunboBox box2">
                        <div class="wrapper">
                            <div class="lunboBox_right">
                                <div class="right_01">检测产业互联网</div>
                                <div class="right_02">检验检测产业链优化创新，构建检验检测生态链</div>
                                <div class="right_02_02">检测上下游企业数字化在线协同</div>
                                <div class="right_03">
                                    <span>了解详情</span>
                                </div>
                            </div>
                            <div class="lunboBox_left">
                                <div>
                                    <!-- <img src="../../assets/gongsi/图1.png" alt="" /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>

                <el-carousel-item>
                    <div class="lunboBox box3">
                        <div class="wrapper">
                            <div class="lunboBox_right">
                                <div class="right_01">报告自动化</div>
                                <div class="right_02">全要素、全数据、全场景高质量报告自动化生成，</div>
                                <div class="right_02_02">协助检测工作者更便捷高效地开展实验室活动</div>
                                <div class="right_03">
                                    <span>了解详情</span>
                                </div>
                            </div>
                            <div class="lunboBox_left">
                                <div>
                                    <!-- <img src="../../assets/gongsi/图1.png" alt="" /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    .carouselDiagram {
        margin-top: 77px;
        height: 700px;
        // background:linear-gradient(to top right,#00223F 0%,#00223F 30%,#045DF3 100% );
        // background-image: url("@/assets/home/Frame 5.png");
        // background-attachment: fixed;
        // margin: 0 auto ;
        .lunboBox {
            // width: 1200px;
            width: 100%;
            // width: 100%;
            height: 100%;
            color: #333333;
            display: flex;
            margin: 0 auto;
            // background-image: url("@/assets/home/Frame 5.png");
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            background-size: cover;
            background-position: center;
            > .wrapper {
                width: 1200px;
                height: 100%;
                color: #333333;
                display: flex;
                margin: 0 auto;
                .lunboBox_right {
                    width: 52%;
                    .right_01 {
                        // min-width: 345px;
                        margin-top: 200px;
                        // margin-left: 100px;
                        white-space: nowrap;
                        width: 462px;
                        height: 106px;
                        font-size: 60px;
                        line-height: 106px;
                        font-weight: bold;
                    }
                    .right_02 {
                        margin-top: 40px;
                        width: 196px;
                        height: 50px;
                        font-size: 28px;
                        line-height: 50px;
                        font-weight: 400;
                        margin-top: 19px;
                        white-space: nowrap;
                        // margin-left: 100px;
                        font-size: 28px;
                    }
                    .right_02_02 {
                        min-width: 552px;
                        // margin-left: 100px;
                        font-size: 28px;
                        white-space: nowrap;
                    }
                    .right_03 {
                        width: 120px;
                        height: 50px;
                        background: #2d74f4;
                        border-radius: 5px 5px 5px 5px;
                        opacity: 1;
                        margin-top: 72px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        font-size: 18px;
                        // width: 140px;
                        // min-width: 140px;
                        // height: 40px;
                        // text-align: center;
                        // line-height: 40px;
                        // margin-top: 60px;
                        // // margin-left: 100px;
                        // font-size: 16px;
                        // background: #fff;
                        // color: black;
                        // border-radius: 5px;
                        // cursor: pointer;
                        // border: 1px solid black;
                        // white-space: nowrap;
                    }
                }
                .lunboBox_left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 170px;
                    min-width: 456px;
                }
            }
        }
        .box3,
        .box1 {
            background-image: url("@/assets/DCC/Group 1000009381.png");
        }
        .box2 {
            background-image: url("@/assets/home/Frame 5.png");
        }
    }
}
/deep/.el-carousel__indicator.is-active button {
    opacity: 1 !important;
    width: 40px;
    height: 5px;
    background-color: #2d74f4 !important;
}
/deep/.el-carousel__button {
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px 5px 5px 5px;
    width: 40px;
    opacity: unset;
    height: 5px;
}
/deep/.el-carousel__indicators--horizontal {
    bottom: 18px !important;
}
/deep/.el-carousel__item.is-animating {
    // transition: none;
    // transition-duration: 300ms;
    // transition-property: opacity;
    // transition-duration: 0.3s;
    // transition-timing-function: linear;
}
</style>
