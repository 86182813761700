<template>
    <div class="container">
        <div class="top">
            <div class="left-wrapper">
                <div class="top_left" @click="jumpPage(0)">
                    <img width="204" src="../../assets/about-us/labbol莱普博乐.png" />
                </div>
                <div class="top_right">
                    <!-- @click="jumpPage(1)" -->
                    <div @mouseenter="enterNavigation('product')" @mouseleave="e => leaveHeaderNavigation(e, 'product')" ref="product" :class="['product', { actives: isActive == 1 }]">
                        产品
                        <i style="margin-top: 4px" class="el-icon-arrow-down"></i>
                        <div ref="product_tag" class="tag"></div>
                        <!-- <div class="product_content">
                            <div class="product_">
                                <div class="content-wrapper">
                                    <div class="product-tabs">
                                        <div class="tab-list">
                                            <div @click="choseProductTab(item)" v-for="(item, index) in productTabs" :key="index" :class="['item', currentProductTab == item ? 'active' : '']">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="apps">
                                        <div class="app-area">
                                            <div v-for="(item, index) in productAppList" :key="index" @click="choseApp(item)" :class="['app-item', currentApp == item.title ? 'active' : '']">
                                                <div class="img">
                                                    <img :src="item.img" width="100%" alt="" />
                                                </div>
                                                <div class="text">
                                                    <div class="title">{{ item.title }}</div>
                                                    <div class="description">{{ item.description }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ad-area">
                                            <div class="img">
                                                <img src="../../assets/headers/Group 1000009315.png" alt="" />
                                            </div>
                                            <div class="title">报告自动化系统</div>
                                            <div class="description">亲自尝试我们的报告自动化系统，您可以立即申请演示</div>
                                            <div class="apply" @click="handleApply">
                                                立即申请
                                                <i class="el-icon-arrow-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div @mouseenter="enterNavigation('scheme')" @mouseleave="e => leaveHeaderNavigation(e, 'scheme')" @click="jumpPage(2)" :class="['scheme', { actives: isActive == 2 }]">
                        解决方案
                        <i style="margin-top: 4px" class="el-icon-arrow-down"></i>
                        <div ref="scheme_tag" class="tag"></div>
                        <!-- <div class="scheme_content">
                            <div class="scheme_">
                                <div class="content-wrapper">
                                    <div class="product-tabs">
                                        <div class="tab-list">
                                            <div @click="choseSchemeTab(item)" v-for="(item, index) in schemeTabs" :key="index" :class="['item', currentSchemeTab == item ? 'active' : '']">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="apps">
                                        <div class="app-area">
                                            <div v-for="(item, index) in schemeAppList" :key="index" @click="choseApp(item)" :class="['app-item', currentApp == item.title ? 'active' : '']">
                                                <div class="img">
                                                    <img :src="item.img" width="100%" alt="" />
                                                </div>
                                                <div class="text">
                                                    <div class="title">{{ item.title }}</div>
                                                    <div class="description">{{ item.description }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ad-area">
                                            <div class="img">
                                                <img src="../../assets/headers/Group 1000009502.png" alt="" />
                                            </div>
                                            <div class="title">定制专属解决方案</div>
                                            <div class="description">从您所取得的检测校准能力范围，提取行业应用特征，共享我们检测数字化实践成果和经验。</div>
                                            <div class="apply">
                                                立即咨询
                                                <i class="el-icon-arrow-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div @click="jumpPage(3)" :class="['news', { actives: isActive == 3 }]">
                        客户案例
                        <div class="tag"></div>
                        <!-- <div class="news_content">
                            <div class="news_">
                                <p @click.stop="getNewsInfo(0)" class="news_01">全部</p>
                                <p @click.stop="getNewsInfo(1)">公司新闻</p>
                                <p @click.stop="getNewsInfo(2)">行业新闻</p>
                            </div>
                        </div> -->
                    </div>
                    <div @click="jumpPage(4)" :class="['about', { actives: isActive == 4 }]">
                        关于我们
                        <div class="tag"></div>
                        <div class="about_content">
                            <div class="content_">
                                <p>公司简介</p>
                                <p>企业荣誉</p>
                                <p>合作伙伴</p>
                                <p>联系我们</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-wrapper">
                <div class="login"><img src="../../assets/about-us/客服.png" alt="" srcset="" /></div>
                <div class="phone">400-0707-820</div>
                <div class="try" @click="handleApply">请求演示</div>
            </div>
        </div>

        <div class="navigation-bar" @mouseleave="leaveNavigation" ref="navigationBar">
            <div v-if="navigation == 'product'" class="product_">
                <div class="content-wrapper">
                    <div class="product-tabs">
                        <div class="tab-list">
                            <div @click="choseProductTab(item)" v-for="(item, index) in productTabs" :key="index" :class="['item', currentProductTab == item ? 'active' : '']">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="apps">
                        <div class="app-area">
                            <div v-for="(item, index) in productAppList" :key="index" @click="choseApp(item)" :class="['app-item', currentApp == item.title ? 'active' : '']">
                                <div class="img">
                                    <img :src="item.img" width="100%" alt="" />
                                </div>
                                <div class="text" >
                                    <div class="title">{{ item.title }}</div>
                                    <div class="description">{{ item.description }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="ad-area">
                            <div class="img">
                                <img src="../../assets/headers/Group 1000009315.png" alt="" />
                            </div>
                            <div class="title">报告自动化系统</div>
                            <div class="description">亲自尝试我们的报告自动化系统，您可以立即申请演示</div>
                            <div class="apply" @click="handleApply">
                                立即申请
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="scheme_">
                <div class="content-wrapper">
                    <div class="product-tabs">
                        <div class="tab-list">
                            <div @click="choseSchemeTab(item)" v-for="(item, index) in schemeTabs" :key="index" :class="['item', currentSchemeTab == item ? 'active' : '']">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="apps">
                        <div class="app-area">
                            <div v-for="(item, index) in schemeAppList" :key="index" @click="choseApp(item)" :class="['app-item', currentApp == item.title ? 'active' : '']">
                                <div class="img">
                                    <img :src="item.img" width="100%" alt="" />
                                </div>
                                <div class="text">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="description">{{ item.description }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="ad-area">
                            <div class="img">
                                <img src="../../assets/headers/Group 1000009502.png" alt="" />
                            </div>
                            <div class="title">定制专属解决方案</div>
                            <div class="description">从您所取得的检测校准能力范围，提取行业应用特征，共享我们检测数字化实践成果和经验。</div>
                            <div class="apply">
                                立即咨询
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters, mapMutations } from "vuex"
export default {
    name: "iHeader",
    props: {
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            titleList: ["首页", "产品中心", "解决方案", "新闻动态", "关于我们"],
            isActive: 0,
            isShow: true,
            productTabs: ["业务应用", "碳达峰、碳中和应用"],
            currentProductTab: "业务应用",
            currentApp: "",
            productAppList: [],
            businessApp: [
                { img: require("@/assets/headers/Frame(1).png"), title: "报告自动化系统", description: "报告文档一键生成" },
                { img: require("@/assets/headers/Frame(4).png"), title: "数字校准证书系统", description: "数字化证书一键生成" },
                { img: require("@/assets/headers/Frame(5).png"), title: "智能检测边缘计算网关", description: "设备互联互通与自动数采" },
                { img: require("@/assets/headers/Frame(8).png"), title: "检测业务管理系统", description: "检测全过程在线管控" },
                { img: require("@/assets/headers/Frame(3).png"), title: "认证业务管理系统", description: "认证全过程在线管控" },
                { img: require("@/assets/headers/Frame(2).png"), title: "质量体系管理系统", description: "体系全要素智能管控" },
                { img: require("@/assets/headers/Frame(6).png"), title: "能力验证管理系统", description: "能力验证服务全过程管控" },
                { img: require("@/assets/headers/Frame(7).png"), title: "风电检测智能辅助系统", description: "风电检测作业和数据分析" },
                { img: require("@/assets/headers/Frame.png"), title: "新型电力系统情报平台", description: "最新动态和新技术探索" },
            ],
            carbonList: [{ img: require("@/assets/headers/Frame1.png"), title: "双碳情报系统", description: "双碳宏观、行业和项目实践" }],
            schemeTabs: ["数字化解决方案"],
            currentSchemeTab: "数字化解决方案",
            schemeAppList: [],
            digitalApp: [
                { img: require("@/assets/headers/scheme/Frame(7).png"), title: "检测标准数字化", description: "机器可读标准" },
                { img: require("@/assets/headers/scheme/Frame(1).png"), title: "报告证书数字化", description: "机器可读报告证书" },
                { img: require("@/assets/headers/scheme/Frame(2).png"), title: "仪器设备数字化", description: "仪器设备感、传、知、控" },
                { img: require("@/assets/headers/scheme/Frame.png"), title: "实验室环境数字化", description: "试验环境智能联控" },
                { img: require("@/assets/headers/scheme/Frame(4).png"), title: "检测产业互联网", description: "试验检测生态建设" },
                { img: require("@/assets/headers/scheme/Frame(3).png"), title: "智慧实验室", description: "检测全要素全链条可追溯" },
                { img: require("@/assets/headers/scheme/Frame(5).png"), title: "检测资源一体化", description: "资源标准化及应用" },
                { img: require("@/assets/headers/scheme/Frame(6).png"), title: "检测数据中心", description: "试验数据汇聚与能力共享" },
            ],
            navigation: "",
            // titleId:null,
        }
    },
    created() {
        this.isActive = this.index
    },
    mounted() {
        this.productAppList = this.businessApp
        this.schemeAppList = this.digitalApp
        // this.localComment();
        if (this.$route.name == "product") {
            this.currentApp = "报告自动化系统"
        } else if (this.$route.name == "dcc") {
            this.currentApp = "数字校准证书系统"
        }
    },
    computed: {
        titleId: function () {
            return this.$store.state.titleId
        },
    },
    watch: {
        titleId: function (val) {
            //  this.$store.commit('SET_TITLE',val)
            // console.log("SET_TITLE-watch", val, this.titleId)
        },
    },
    methods: {
        enterNavigation(e) {
            this.navigation = e
            this.$refs[`${e}_tag`].style.display = "block"
            this.$refs.navigationBar.style.height = "340px"
        },
        leaveNavigation() {
            this.$refs.navigationBar.style.height = "0px"
            this.$refs[`${this.navigation}_tag`].style.display = "none"
        },
        leaveHeaderNavigation(e, tagName) {
            if (e.toElement._prevClass !== "apps") {
                this.$refs[`${tagName}_tag`].style.display = "none"
                this.$refs.navigationBar.style.height = "0px"
            }
        },
        choseApp(item) {
            console.log('item',item);
            this.currentApp = item.title
            if (item.title == "报告自动化系统") {
                this.$router.push({ name: "product" })
            } else if (item.title == "数字校准证书系统") {
                this.$router.push({ name: "dcc" })
            }else if(item.title == "双碳情报系统"){
                window.open('http://cooask.com','_blank')
            }
            else if(item.title == "智慧实验室")
            {
                this.$router.push({ name: "smartLab" })  
            }
        },
        choseProductTab(item) {
            this.currentProductTab = item
            if (this.currentProductTab == "业务应用") {
                this.productAppList = this.businessApp
            } else {
                this.productAppList = this.carbonList
            }
        },
        choseSchemeTab(item) {
            this.currentSchemeTab = item
            if (this.currentProductTab == "数字化解决方案") {
                this.productAppList = this.businessApp
            }
        },
        getNewsInfo(e) {
            let routeUrl = this.$router.resolve({
                path: "/news/newsInfo", //新页面地址
                query: { id: e }, //携带的参数
            })
            window.open(routeUrl.href, "_blank")
        },
        toDigital() {
            this.$router.push({ path: "/dcc" })
        },
        jumpPage(index) {
            this.isActive = index
            // this.$store.commit("SET_TITLE", index);
            ////#region
            if (index == 0) {
                // let routeUrl = this.$router.resolve({
                //   path: "/", //新页面地址
                //   query: {index:index} //携带的参数
                // });
                // window.open(routeUrl.href, routeUrl.href);
                // this.$router.push({ path: "/home" })
                this.$router.push({ path: "/" })
            }
            if (index == 1) {
                this.$router.push({
                    path: "/product",
                    // query: { index: this.isActive },
                })
            }
            if (index == 2) {
                return
                this.$router.push({ path: "/scheme", query: { index: this.isActive } })
            }
            if (index == 3) {
                this.$router.push({ path: "/CustomerCase", query: { index: this.isActive } })
            }
            if (index == 4) {
                this.$router.push({ path: "/about", query: { index: this.isActive } })
            }
            ////#endregion
        },
        //申请演示
        handleApply() {
            let locationUrl = this.$router.resolve({ path: "/requestPresentation" })
            window.open(locationUrl.href, "_blank")
        },
        // localComment(){
        //   let localId = localStorage.getItem('titleId')
        //   this.titleId =localId
        // }
    },
}
</script>


<style lang="less" scoped>
.container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: #fff;
    display: flex;
    color: #333;
    justify-content: center;
    align-items: center;
    .top {
        width: 1200px;
        // height: 100px;
        height: 80px;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .left-wrapper {
            width: 634px;
            height: 100%;
            color: #333;
            display: flex;
            justify-content: start;
            align-items: center;
            .top_left {
                margin-left: 0px;
                // line-height: 80px;
                cursor: pointer;
                img {
                    // width: 208px;
                    // height: 32px;
                }
            }
            .top_right {
                display: flex;
                width: 362px;
                white-space: nowrap;
                justify-content: space-between;
                margin-left: 75px;
                font-size: 16px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                height: 100%;
                // line-height: 80px;
                cursor: pointer;
                .product {
                    position: relative;
                    display: flex;
                    align-items: center;
                    & {
                        .product_content {
                            // background-color: #2a2b2c;
                            // opacity: 0.8;
                            // height: 244px;
                            // height: 50px;
                            height: 340px;
                        }
                        > .tag {
                            width: 74px;
                            height: 2px;
                            display: none;
                            top: 76px;
                            left: -18px;
                            background: #2d74f4;
                            position: absolute;
                        }
                    }
                }
                .scheme {
                    display: flex;
                    position: relative;
                    align-items: center;
                    &:hover {
                        .scheme_content {
                            // background-color: #2a2b2c;
                            // opacity: 0.8;
                            // height: 200px;
                            height: 340px;
                        }
                        > .tag {
                            width: 74px;
                            height: 2px;
                            top: 76px;
                            display: none;
                            left: -6px;
                            background: #2d74f4;
                            position: absolute;
                        }
                    }
                }
                .news {
                    display: flex;
                    position: relative;
                    align-items: center;
                    &:hover {
                        .news_content {
                            // background-color: #2a2b2c;
                            // opacity: 0.8;
                            // height: 120px;
                        }
                        > .tag {
                            width: 74px;
                            height: 2px;
                            top: 76px;
                            left: -6px;
                            background: #2d74f4;
                            position: absolute;
                        }
                    }
                }
                .about {
                    display: flex;
                    align-items: center;
                    position: relative;
                    &:hover {
                        .about_content {
                            // background-color: #2a2b2c;
                            // opacity: 0.8;
                            // height: 160px;
                        }
                        > .tag {
                            width: 74px;
                            height: 2px;
                            top: 76px;
                            left: -6px;
                            background: #2d74f4;
                            position: absolute;
                        }
                    }
                }
                .product_content {
                    width: 1920px;
                    background: #ffffff;
                    box-shadow: 0px 15px 20px 0px rgba(14, 51, 117, 0.1);
                    // width: 134px;
                    // height: 340px;
                    height: 0px;
                    overflow: hidden;
                    // overflow-y: auto;
                    // line-height: 40px;
                    // background: #fff;
                    color: #333;
                    font-size: 16px;
                    font-weight: 500;
                    // text-align: center;
                    position: absolute;
                    top: 80px;
                    left: -641px;
                    // margin-left: -46px;
                    transition: all 0.5s;
                    cursor: default;
                    // left: 0px;

                    .product_ {
                        // margin-left: 16.6%;
                        min-width: 1200px;
                        box-shadow: 0px 15px 20px 0px rgba(14, 51, 117, 0.1);
                        width: 100%;
                        height: 100%;
                        line-height: unset;
                        display: flex;
                        justify-content: center;
                    }
                }
                .scheme_content {
                    // width: 180px;
                    // width: 150px;
                    width: 1920px;
                    background: #ffffff;
                    box-shadow: 0px 15px 20px 0px rgba(14, 51, 117, 0.1);
                    height: 0px;
                    // height: 340px;
                    overflow: hidden;
                    // overflow-y: auto;
                    // line-height: 40px;
                    // background: #2a2b2c;
                    // color: #fff;
                    // background: #fff;
                    color: #333;
                    font-size: 16px;
                    font-weight: 500;
                    // text-align: center;
                    position: absolute;
                    top: 80px;
                    left: -716px;
                    cursor: default;
                    // left:50px;
                    // margin-left: -36px;
                    transition: all 0.5s;
                    > .tag {
                        width: 74px;
                        height: 2px;
                        background: #2d74f4;
                        margin-left: 35px;
                    }
                    .scheme_ {
                        // margin-left: 16.6%;
                        min-width: 1200px;
                        width: 100%;
                        box-shadow: 0px 15px 20px 0px rgba(14, 51, 117, 0.1);
                        height: 100%;
                        line-height: unset;
                        display: flex;
                        justify-content: center;
                    }
                }
                .news_content {
                    width: 134px;
                    height: 0px;
                    overflow: hidden;
                    // overflow-y: auto;
                    line-height: 40px;
                    background: #fff;
                    color: #333;
                    // background: #2a2b2c;
                    // color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    // text-align: center;
                    position: absolute;
                    top: 73px;
                    margin-left: -25px;
                    transition: all 0.5s;
                    > .tag {
                        width: 74px;
                        height: 2px;
                        background: #2d74f4;
                        margin-left: 26px;
                    }
                    .news_ {
                        // margin-left: 35%;
                        > p {
                            display: flex;
                            height: 30px;
                            font-size: 14px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            line-height: 30px;
                            align-items: center;
                            justify-content: center;
                            &:hover {
                                background: #f2f8ff;
                                color: #2d74f4;
                            }
                        }
                    }
                }
                .about_content {
                    width: 134px;
                    height: 0px;
                    overflow: hidden;
                    // overflow-y: auto;
                    line-height: 40px;
                    background: #fff;
                    color: #333;
                    // background: #2a2b2c;
                    // color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    position: absolute;
                    top: 73px;
                    margin-left: -26px;
                    transition: all 0.5s;
                    > .tag {
                        width: 74px;
                        height: 2px;
                        background: #2d74f4;
                        margin-left: 26px;
                    }
                }
                p {
                    display: flex;
                    height: 30px;
                    font-size: 14px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    line-height: 30px;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        background: #f2f8ff;
                        color: #2d74f4;
                        cursor: pointer;
                    }
                }
                // p:hover {
                //     color: #2894f7;
                //     cursor: pointer;
                // }
            }
            .home:hover {
                color: #333;
                cursor: pointer;
            }
            .product:hover {
                color: #333;
                cursor: pointer;
            }
            .scheme:hover {
                color: #333;
                cursor: pointer;
            }
            .news:hover {
                color: #333;
                cursor: pointer;
            }
            .about:hover {
                color: #333;
                cursor: pointer;
            }
        }
        > .right-wrapper {
            width: 347px;
            height: 100%;
            display: flex;
            
            align-items: center;
            font-size: 15px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            > .try {
                width: 94px;
                height: 39px;
                background: #2d74f4;
                // border-radius: 10px 10px 10px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                cursor: pointer;
            }
            >.phone{
                font-weight: bold;
                font-size: 23px;
                color:#166CC9;
              margin:0 40px 0 20px;
            }
        }
    }
    .navigation-bar {
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        z-index: 99;
        // height: 340px;
        height: 0;
        background-color: #fff;
        overflow: hidden;
        display: flex;
        color: #333;
        justify-content: center;
        transition: all 0.5s;
        box-shadow: inset 3px 0px 20px 0px rgba(14, 51, 117, 0.1);
        // box-shadow: 10px 0px 15px 0px rgba(45, 116, 244, 0.05);
        // box-shadow: 0px 15px 20px 0px rgba(14, 51, 117, 0.1);
        .product_ {
            // margin-left: 16.6%;
            width: 1200px;
            // box-shadow: 0px 15px 20px 0px rgba(14, 51, 117, 0.1);
            // width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            > .content-wrapper {
                width: 1200px;
                // background: #000;
                height: 100%;
                display: flex;
                > .product-tabs {
                    width: 180px;
                    height: 100%;
                    box-shadow: 10px 0px 15px 0px rgba(45, 116, 244, 0.05);
                    box-sizing: border-box;
                    padding: 40px 10px 0 0;
                    > .tab-list {
                        // margin-top: 40px;
                        // margin-right: 10px;
                        height: 100px;
                        width: 170px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        > .item {
                            cursor: pointer;
                            width: 170px;
                            height: 40px;
                            border-radius: 5px 5px 5px 5px;
                            box-sizing: border-box;
                            padding: 12px 17px 14px;
                            font-size: 14px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            line-height: 14px;
                            color: #333;
                        }
                        > .active {
                            color: #2d74f4;
                            background: #f2f8ff;
                        }
                    }
                }
                > .apps {
                    flex: 1;
                    display: flex;
                    box-sizing: border-box;
                    padding: 40px 0 0 55px;
                    justify-content: space-between;
                    > .app-area {
                        width: 630px;
                        height: 228px;
                        display: grid;
                        grid-template-columns: 190px 190px 190px;
                        grid-template-rows: 56px 56px 56px;
                        grid-gap: 30px 30px;
                        > .app-item {
                            box-sizing: border-box;
                            padding: 10px 0px 10px 8px;
                            display: flex;
                            cursor: pointer;
                            > .img {
                                margin-top: 3px;
                            }
                            > .text {
                                margin-left: 10px;
                                > .title {
                                    font-size: 14px;
                                    color: #333333;
                                    // line-height: 28px;
                                }
                                > .description {
                                    margin-top: 3px;
                                    font-size: 12px;
                                    // line-height: 24px;
                                    color: #666666;
                                }
                            }
                        }
                        > .active {
                            background: #f2f8ff;
                            border-radius: 5px 5px 5px 5px;
                        }
                    }
                    > .ad-area {
                        width: 200px;
                        height: 260px;
                        background: #f4f8ff;
                        border-radius: 6px 6px 6px 6px;
                        overflow: hidden;
                        > .title {
                            width: 112px;
                            height: 32px;
                            font-size: 16px;
                            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 32px;
                            margin-left: 12px;
                        }
                        > .description {
                            font-size: 12px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            color: #666666;
                            line-height: 21px;
                            margin-left: 12px;
                            white-space: pre-wrap;
                        }
                        > .apply {
                            cursor: pointer;
                            margin-top: 24px;
                            margin-left: 12px;
                            font-size: 14px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            color: #2d74f4;
                            line-height: 28px;
                            // >i{
                            //     font-size: 12px;
                            // }
                        }
                    }
                }
            }
        }
        .scheme_ {
            // margin-left: 16.6%;
            // min-width: 1200px;
            // width: 100%;
            // box-shadow: 0px 15px 20px 0px rgba(14, 51, 117, 0.1);
            height: 100%;
            line-height: unset;
            // display: flex;
            display: flex;
            justify-content: center;
            > .content-wrapper {
                width: 1200px;
                // background: #000;
                height: 100%;
                display: flex;
                > .product-tabs {
                    width: 180px;
                    height: 100%;
                    box-shadow: 10px 0px 15px 0px rgba(45, 116, 244, 0.05);
                    box-sizing: border-box;
                    padding: 40px 10px 0 0;
                    > .tab-list {
                        // margin-top: 40px;
                        // margin-right: 10px;
                        height: 100px;
                        width: 170px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        > .item {
                            cursor: pointer;
                            width: 170px;
                            height: 40px;
                            border-radius: 5px 5px 5px 5px;
                            box-sizing: border-box;
                            padding: 12px 17px 14px;
                            font-size: 14px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            line-height: 14px;
                            color: #333;
                        }
                        > .active {
                            color: #2d74f4;
                            background: #f2f8ff;
                        }
                    }
                }
                > .apps {
                    flex: 1;
                    display: flex;
                    box-sizing: border-box;
                    padding: 40px 0 0 55px;
                    justify-content: space-between;
                    > .app-area {
                        width: 630px;
                        height: 228px;
                        display: grid;
                        grid-template-columns: 190px 190px 190px;
                        grid-template-rows: 56px 56px 56px;
                        grid-gap: 30px 30px;
                        > .app-item {
                            box-sizing: border-box;
                            padding: 10px 0px 10px 8px;
                            display: flex;
                            cursor: pointer;
                            > .img {
                                margin-top: 3px;
                            }
                            > .text {
                                margin-left: 10px;
                                > .title {
                                    font-size: 14px;
                                    color: #333333;
                                    // line-height: 28px;
                                }
                                > .description {
                                    margin-top: 3px;
                                    font-size: 12px;
                                    // line-height: 24px;
                                    color: #666666;
                                }
                            }
                        }
                        > .active {
                            background: #f2f8ff;
                        }
                    }
                    > .ad-area {
                        width: 200px;
                        height: 260px;
                        background: #f4f8ff;
                        border-radius: 6px 6px 6px 6px;
                        overflow: hidden;
                        > .title {
                            width: 112px;
                            height: 32px;
                            font-size: 16px;
                            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 32px;
                            white-space: nowrap;
                            margin-left: 12px;
                        }
                        > .description {
                            font-size: 12px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            color: #666666;
                            line-height: 21px;
                            margin-left: 12px;
                            white-space: pre-wrap;
                        }
                        > .apply {
                            cursor: pointer;
                            margin-top: 7px;
                            margin-left: 12px;
                            font-size: 14px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            color: #2d74f4;
                            line-height: 28px;
                            // >i{
                            //     font-size: 12px;
                            // }
                        }
                    }
                }
            }
        }
        > .tag {
            width: 74px;
            height: 2px;
            background: #2d74f4;
            margin-left: 35px;
        }
    }
}
.active {
    color: #333;
}
.actives {
    position: relative;
    color: #333;
    &::before {
        content: "";
        position: absolute;
        width: 74px;
        left: -7px;
        height: 2px;
        background: #2d74f4;
        top: 76px;
    }
}
/deep/.el-carousel__indicators--horizontal {
    bottom: 30px !important;
}
</style>

