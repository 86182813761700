export default class storage {
    static sessionStoreSet(key, value) {
        // const Store = require("electron-store");
        // var store = new Store();
        return sessionStorage.setItem(key, value);
    }
    static sessionStoreGet(key) {
        // const Store = require("electron-store");
        // var store = new Store();
        return sessionStorage.getItem(key);
    }
    static localStoreSet(key, value) {
        return this.sessionStoreSet(key, value);
    }
    static localStoreGet(key) {
        return this.sessionStoreGet(key);
    }
}