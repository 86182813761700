import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from 'axios'
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
import VueAMap from "vue-amap";
import api from './api/api';
import storage from './assets/js/storage'


Vue.use(ElementUI)
Vue.use(VueAMap)

VueAMap.initAMapApiLoader({
  key:'ea3437bbd80e5f128682e2328dcad94d',
  plugin:[
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor'
  ],
  uiVersion:'1.0',
  v:'1.4.4'
})

Vue.prototype.$axios = axios;
Vue.prototype.api = api;
Vue.prototype.storage = storage;
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
