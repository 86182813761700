/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import home from './modules/home'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home,
  },
  state: { titleId:0,},
  getters: {},
  mutations: {
    SET_TITLE:(state,val)=>{
      state.titleId =val 
      localStorage.setItem('titleId',val)
  },
  },
  actions: {},
  modules: {},
});
