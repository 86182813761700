import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=62af9274"
import script from "./confirm.vue?vue&type=script&lang=js"
export * from "./confirm.vue?vue&type=script&lang=js"
import style0 from "./confirm.vue?vue&type=style&index=0&id=62af9274&prod&scope=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports