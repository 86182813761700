import http from "../utils/http"
//阿里云
// let newsIp = "http://47.104.68.37:13006";
// let newsIp = "http://192.168.23.21:13005";
let newsIp = "/api"
//测试地址
// let newsIp = ""

export default class api {
    //==========↓==========↓==========↓==========↓==========GET==========↓==========↓==========↓==========↓==========

    /**
     *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
     *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
     */

    static getNewsAPI(params, url) {
        return http.get(`${newsIp}${url}`, params)
    }

    //==========↑==========↑==========↑==========↑==========GET==========↓==========↓==========↓==========↓==========
    static getFormAPI(params, url) {
        return http.get(`${newsIp}${url}`, params)
    }

    //==========↓==========↓==========↓==========↓==========POST==========↓==========↓==========↓==========↓==========
    static postAddressAPI(params, url, urlData) {
        return http.post(`${newsIp}${url}`, params, urlData)
    }
}
