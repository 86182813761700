<template>
    <div class="container">
        <i-header :index="index" />
        <div class="body">
            <!-- 轮播图 -->
            <!-- <div class="carouselDiagram">
        <el-carousel :interval="100000000000" height="480px">
          <el-carousel-item>
            <div class="lunboBox">
              <div class="lunboBox_right">
                <div class="right_01">检测产业互联网</div>
                <div class="right_02">检验检测产业链优化创新，构建检验检测生态链，检测上下游</div>
                <div class="right_02_02">企业数字化在线协同</div>
                <div class="right_03">
                  <span>了解详情</span>
                </div>
              </div>
              <div class="lunboBox_left">
                <div>
                  <img src="../assets/gongsi/图1.png" alt="">
                </div>
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item>
            <div class="lunboBox">
              <div class="lunboBox_right">
                <div class="right_01">检测产业互联网</div>
                <div class="right_02">检验检测产业链优化创新，构建检验检测生态链，检测上下游</div>
                <div class="right_02_02">企业数字化在线协同</div>
                <div class="right_03">
                  <span>了解详情</span>
                </div>
              </div>
              <div class="lunboBox_left">
                <div>
                  <img src="../assets/gongsi/图1.png" alt="">
                </div>
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item>
            <div class="lunboBox">
              <div class="lunboBox_right">
                <div class="right_01">检测产业互联网</div>
                <div class="right_02">检验检测产业链优化创新，构建检验检测生态链，检测上下游</div>
                <div class="right_02_02">企业数字化在线协同</div>
                <div class="right_03">
                  <span>了解详情</span>
                </div>
              </div>
              <div class="lunboBox_left">
                <div>
                  <img src="../assets/gongsi/图1.png" alt="">
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div> -->
            <carouselDiagram></carouselDiagram>
            <!-- 产品中心 -->
            <div class="productCenter">
                <div class="product_title">产品中心</div>
                <div class="product_body">
                    <swiper :options="swiperOptions">
                        <swiper-slide style="display: flex; justify-content: center">
                            <div class="product_box">
                                <div class="imgbox">
                                    <img src="../assets/sucai/su03.png" />
                                </div>
                                <div class="product_text">
                                    <p>业务管理</p>
                                    <div class="title_detail">
                                        实现从试验委托申请、委托协议签订、样品登记、工作计划指令、检测任务派发、检测、原始记录和检测报告/证书出具的试验检测全生命周期过程管理和跟踪。
                                    </div>
                                </div>
                            </div>
                            <div class="product_box">
                                <div class="imgbox">
                                    <img src="../assets/sucai/su10.png" />
                                </div>
                                <div class="product_text">
                                    <p>样品管理</p>
                                    <div class="title_detail">实现样品跟踪、样品登记、样品拍照、样品入库、样品条码标签打印、样品出库、样品取样、留样管理等。</div>
                                </div>
                            </div>
                            <div class="product_box">
                                <div class="imgbox">
                                    <img src="../assets/imagin/peoduct02.png" />
                                </div>
                                <div class="product_text">
                                    <p>资源管理</p>
                                    <div class="title_detail">
                                        严格遵循IEC/ISO
                                        17025标准，实现实验室、人员、仪器设备、检测对象、检测依据、环境等实验室资源的集中管理，跟踪实验资源当前状态，为检测生产和报告出具提供合法性保障。
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide style="display: flex; justify-content: center">
                            <div class="product_box">
                                <div class="imgbox">
                                    <img src="../assets/sucai/su04.png" />
                                </div>
                                <div class="product_text">
                                    <p>数据管理</p>
                                    <div class="title_detail">实现试验数据与过程数据的集中管理，具备试验数据的自动采集、自动计算、自动判定，支持试验数据杠改，提升试验数据准确性且具备可追溯性。</div>
                                </div>
                            </div>
                            <div class="product_box">
                                <div class="imgbox">
                                    <img src="../assets/sucai/su05.png" />
                                </div>
                                <div class="product_text">
                                    <p>体系管理</p>
                                    <div class="title_detail">
                                        针对实验室管理体系的现状和适应性进行系统评价，确保实现实验室的质量方针和目标，并保证质量管理体系持续和有效运行，适用管理体系涉及的各部门和管理、技术要素的审核。
                                    </div>
                                </div>
                            </div>
                            <div class="product_box">
                                <div class="imgbox">
                                    <img src="../assets/sucai/su06.png" />
                                </div>
                                <div class="product_text">
                                    <p>客户网上服务中心</p>
                                    <div class="title_detail">向客户提供试验检测公告通知、试验委托在线预约与进度跟踪、报告查询、报告验签、检测能力及客户服务相关服务。</div>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>

                    <!-- <div class="product_box">
                <div class="product_text">
                  <p>智慧检测服务云</p>
                  <el-divider class="product_line"></el-divider>
                  <div
                    class="title_detail"
                  >检测服务SaaS平台，以多租户模式驱动“一站式”网上服务，实现检测服务模式从分散式到集中式的转变，为检验检测产业链上下游企业提供在线检验检测智能化服务。</div>
                </div>
          </div>
          <div class="product_box">
                <div class="product_text">
                  <p>智慧检测服务云</p>
                  <el-divider class="product_line"></el-divider>
                  <div
                    class="title_detail"
                  >检测服务SaaS平台，以多租户模式驱动“一站式”网上服务，实现检测服务模式从分散式到集中式的转变，为检验检测产业链上下游企业提供在线检验检测智能化服务。</div>
                </div>
          </div>
          <div class="product_box">
                <div class="product_text">
                  <p>智慧检测服务云</p>
                  <el-divider class="product_line"></el-divider>
                  <div
                    class="title_detail"
                  >检测服务SaaS平台，以多租户模式驱动“一站式”网上服务，实现检测服务模式从分散式到集中式的转变，为检验检测产业链上下游企业提供在线检验检测智能化服务。</div>
                </div>
          </div>
          <div class="product_box">
                <div class="product_text">
                  <p>智慧检测服务云</p>
                  <el-divider class="product_line"></el-divider>
                  <div
                    class="title_detail"
                  >检测服务SaaS平台，以多租户模式驱动“一站式”网上服务，实现检测服务模式从分散式到集中式的转变，为检验检测产业链上下游企业提供在线检验检测智能化服务。</div>
                </div>
          </div> -->
                </div>
                <!-- <div class="product_bottom">
          <span >查看更多></span>
        </div> -->
            </div>

            <!-- 解决方案 -->
            <div class="solutionScheme">
                <div class="scheme_title">解决方案</div>
                <div class="scheme_body">
                    <div class="scheme_box">
                        <div style="overflow: hidden">
                            <img src="../assets/sucai/su12.png" alt />
                        </div>
                        <div style="padding: 36px 57px">
                            <p style="font-size: 22px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 500; color: #0075c9; margin-bottom: 12px">检测实验室数字化解决方案</p>
                            <div style="font-size: 16px; font-family: PingFang SC, PingFang SC-Regular; font-weight: 400; color: rgba(0, 0, 0, 0.65); line-height: 28px">
                                提供试验检测业务一站式全生命周期管理能力和数字化运营能力，助力实验室能效提升。
                            </div>
                        </div>
                    </div>

                    <div class="scheme_box">
                        <div style="overflow: hidden">
                            <img src="../assets/imagin/scheme02.png" alt />
                        </div>
                        <div style="padding: 36px 57px">
                            <p style="font-size: 22px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 500; color: #0075c9; margin-bottom: 12px">计量实验室数字化解决方案</p>
                            <div style="font-size: 16px; font-family: PingFang SC, PingFang SC-Regular; font-weight: 400; color: rgba(0, 0, 0, 0.65); line-height: 28px">
                                提供计量检定校准业务一站式全生命周期管理能力和数字化运营能力，助力实验室能效提升。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scheme_body">
                    <div class="scheme_box">
                        <div style="overflow: hidden">
                            <img src="../assets/imagin/scheme03.png" alt />
                        </div>
                        <div style="padding: 36px 57px">
                            <p style="font-size: 22px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 500; color: #0075c9; margin-bottom: 12px">认证领域数字化解决方案</p>
                            <div style="font-size: 16px; font-family: PingFang SC, PingFang SC-Regular; font-weight: 400; color: rgba(0, 0, 0, 0.65); line-height: 28px">
                                提供设备互联、智能仿真、数据管理等可视化模块化开发工具。加速工业场景数字化转型。
                            </div>
                        </div>
                    </div>

                    <div class="scheme_box">
                        <div style="overflow: hidden">
                            <img src="../assets/imagin/scheme04.png" alt />
                        </div>
                        <div style="padding: 36px 57px">
                            <p style="font-size: 22px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 500; color: #0075c9; margin-bottom: 12px">质量体系领域数字化解决方案</p>
                            <div style="font-size: 16px; font-family: PingFang SC, PingFang SC-Regular; font-weight: 400; color: rgba(0, 0, 0, 0.65); line-height: 28px">
                                提供设备互联、智能仿真、数据管理等可视化模块化开发工具。加速工业场景数字化转型。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 新闻动态 -->
            <!-- <div class="reportNews">
        <div class="news_title">新闻动态</div>
        <div class="news_body">
          <div style="min-height:300px;">
          <div class="news_box" v-for="(item , index) in newsList" :key="index">
            <div :class="['text',{active:isActive == index}]" @click="checkNews(item,index)">
                <div style="width:400px;white-space:nowrap;text-overflow:ellipsis">{{item.noticeTitle}}</div>
              <div style="margin-right:500px;">{{item.issueDate}}</div>
            </div>
          </div>
          </div>
          <div class="news_bottom" @click="checkMore()">>>>more</div>
        </div>
      </div> -->
            <!-- 客户案例 -->
            <div class="customer">
                <div class="customer_title">客户案例</div>
                <div class="customer_body">
                    <!-- <div style="min-height:300px;">
          <div class="news_box" v-for="(item , index) in newsList" :key="index">
            <div :class="['text',{active:isActive == index}]" @click="checkNews(item,index)">
                <div style="width:400px;white-space:nowrap;text-overflow:ellipsis">{{item.noticeTitle}}</div>
              <div style="margin-right:500px;">{{item.issueDate}}</div>
            </div>
          </div>
          </div>
          <div class="news_bottom" @click="checkMore()">>>>more</div> -->
                    <img src="../assets/gongsi/kehu.jpg" alt="" />
                </div>
            </div>
        </div>
        <i-footer style="margin-top: 0px" />
    </div>
</template>
<script>
/* eslint-disable */
import { swiper, swiperSlide } from "vue-awesome-swiper"
import "swiper/css/swiper.min.css"
import iHeader from "@/components/Home/iHeader.vue"
import iFooter from "@/components/Home/iFooter.vue"
import carouselDiagram from "@/components/Home/carouselDiagram.vue"
import adv_01 from "@/assets/imagin/u111.png"
import adv_02 from "@/assets/imagin/u116.png"
import adv_03 from "@/assets/imagin/u120.png"
import adv_04 from "@/assets/imagin/u124.png"
import adv_05 from "@/assets/imagin/u59.png"
import adv_06 from "@/assets/imagin/u64.png"
import adv_07 from "@/assets/imagin/u69.png"

export default {
    name: "HomeView",
    components: {
        iHeader,
        iFooter,
        swiper,
        swiperSlide,
        carouselDiagram,
    },
    data() {
        return {
            pageNum: 1,
            pageSize: 7,
            swiperOptions: {
                spaceBetween: 30,
                autoplay: false,
                loop: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
            isActive: null,
            index: 0,
            imaginList: [
                {
                    title: "1111",
                    url: adv_05,
                },
                {
                    title: "1111",
                    url: adv_06,
                },
                {
                    title: "1111",
                    url: adv_07,
                },
            ],
            productList: [
                {
                    title: "实验室信息管理系统",
                    url: adv_01,
                    content:
                        "依托物联网技术，构建安全生产综合信息平台，帮助企业通过“人防+技防”有机结合，实现：“间断性检查”“连续性实时监控”转变、“人为判断”向“智能分析”转变、“事后反应”向“自动响应”转变。",
                },
                {
                    title: "智慧实验室",
                    url: adv_02,
                    content:
                        "依托物联网技术，构建安全生产综合信息平台，帮助企业通过“人防+技防”有机结合，实现：“间断性检查”“连续性实时监控”转变、“人为判断”向“智能分析”转变、“事后反应”向“自动响应”转变。",
                },
                {
                    title: "质量管理体系",
                    url: adv_03,
                    content:
                        "依托物联网技术，构建安全生产综合信息平台，帮助企业通过“人防+技防”有机结合，实现：“间断性检查”“连续性实时监控”转变、“人为判断”向“智能分析”转变、“事后反应”向“自动响应”转变。",
                },
                {
                    title: "输电线路监测数据系统",
                    url: adv_04,
                    content:
                        "依托物联网技术，构建安全生产综合信息平台，帮助企业通过“人防+技防”有机结合，实现：“间断性检查”“连续性实时监控”转变、“人为判断”向“智能分析”转变、“事后反应”向“自动响应”转变。",
                },
            ],
            schemeList: [
                {
                    title: "数字化实验室解决方案",
                    url: adv_01,
                },
                {
                    title: "仪器设备数字化管理解决方案",
                    url: adv_02,
                },
                { title: "离线数据采集解决方案", url: adv_03 },
                { title: "质量管理体系解决方案", url: adv_04 },
            ],
            newsList: [
                {
                    title: "莱普博乐承建国网湖北省电力公司计量中心检测业务应用管理系统正式上线",
                    time: "2016-06-15",
                },
                {
                    title: "认监委：今年将出台检验检测机构管理改革措施",
                    time: "2014-05-28",
                },
                {
                    title: "全国检验检测服务业统计信息首次发布",
                    time: "2015-03-09",
                },
                {
                    title: "国家质检总局计量司副司长到中国电科院调研计量工作",
                    time: "2015-03-09",
                },
                {
                    title: "国家电网公司顾问张文亮、王相勤到国网计量中心调研",
                    time: "2015-03-09",
                },
                {
                    title: "首家电力行业高电压试验人员评价基地落户中国电科院",
                    time: "2022-01-27",
                },
            ],
        }
    },
    created() {
        // this.getNews()
        if (this.$route.query.index) {
            this.index = this.$route.query.index
        }
    },
    mounted() {
        // this.initSwoper();
        if (this.$route.query.index) {
            this.index = this.$route.query.index
        }
    },
    methods: {
        checkNews(e, index) {
            this.isActive = index
            let routeUrl = this.$router.resolve({
                path: "/news/newsDetail", //新页面地址
                query: { id: e.noticeId }, //携带的参数
            })
            window.open(routeUrl.href, routeUrl.href)
        },
        checkMore() {
            this.$store.commit("SET_TITLE", 3)
            this.$router.push({ path: "/news", query: { index: 3 } })
        },
        //调取新闻接口
        getNews() {
            let data = {}
            this.api
                .getNewsAPI(data, `/system/notice/list?pageNum=${this.pageNum}&pageSize=${this.pageSize}`)
                .then(res => {
                    console.log("res-news", res)
                    this.newsList = res.data.rows
                })
                .catch(err => {})
        },
        //初始化swiper
        // initSwoper() {
        //   this.$nextTick(() => {
        //     new Swiper(".swiper-container", {
        //       loop: true,
        //       autoplay: 1000,
        //       slidesPerview: 4,
        //       spaceBetween: 18,
        //       prevButton: "swiper-button-prev",
        //       nextButton: "swiper-button-next"
        //     });
        //   });
        // }
    },
}
</script>

<style lang="less" scoped>
// @import "../../node_modules/swiper/dist/css/swiper.css";

.container {
    width: 100%;
    min-width: 1200px;
    .body {
        width: 100%;
        .carouselDiagram {
            margin-top: 60px;
            height: 480px;
            background: linear-gradient(to top right, #00223f 0%, #00223f 30%, #045df3 100%);
            // margin: 0 auto ;
            .lunboBox {
                width: 1200px;
                height: 100%;
                color: #fff;
                display: flex;
                margin: 0 auto;
                .lunboBox_right {
                    width: 52%;
                    .right_01 {
                        // min-width: 345px;
                        margin-top: 60px;
                        margin-left: 100px;
                        white-space: nowrap;
                        font-size: 48px;
                        font-weight: bold;
                    }
                    .right_02 {
                        margin-top: 40px;
                        min-width: 552px;
                        white-space: nowrap;
                        margin-left: 100px;
                        font-size: 20px;
                    }
                    .right_02_02 {
                        min-width: 552px;
                        margin-left: 100px;
                        font-size: 20px;
                        white-space: nowrap;
                    }
                    .right_03 {
                        width: 140px;
                        min-width: 140px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        margin-top: 60px;
                        margin-left: 100px;
                        font-size: 16px;
                        background: #fff;
                        color: black;
                        border-radius: 5px;
                        cursor: pointer;
                        border: 1px solid black;
                        white-space: nowrap;
                    }
                }
                .lunboBox_left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 170px;
                    min-width: 456px;
                }
            }
        }

        // .productCenter {
        //   // width: 100%;
        //   // height: 500px;
        //   background: #FFFFFF;
        //   padding: 68px 0 80px 0;
        //   .product_title {
        //     font-size: 27px;
        //     width: 250px;
        //     margin: 0px auto 40px;
        //     // padding-top: 30px;
        //     padding-bottom: 16px;
        //     text-align: center;
        //     border-bottom: 3px solid #dfe0e1;
        //     border-radius: 2px;
        //     position: relative;
        //   }
        //   .product_title:after {
        //     content: "";
        //     height: 3px;
        //     width: 33.3%;
        //     background-color: #2894f7;
        //     position: absolute;
        //     left: 0;
        //     bottom: -3px;
        //     border-radius: 2px;
        //   }
        //   .product_body {
        //     min-width: 70%;
        //     width: 70%;
        //     margin: 0 auto;
        //     margin-bottom: 30px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;
        //     .product_box {
        //       width: 270px;
        //       min-width: 270px;
        //       height: 340px;
        //       font-size: 16px;
        //       cursor: pointer;
        //       background: #ffffff;
        //       border: 1px solid #797979;
        //       border-radius: 10px;
        //       margin-right: 25px;
        //     }
        //     img:hover {
        //       transform: scale(1.2);
        //       transition: all 0.4s;
        //     }
        //     .product_text {
        //       height: 210px;
        //       p {
        //         font-weight: bold;
        //         margin: 32px 20px 20px 20px;
        //       }
        //       .product_line{
        //         width: 90%;
        //         margin: 0 auto;
        //         background: #000;
        //       }
        //       .title_detail {
        //         margin:32px 20px 20px 20px ;
        //         line-height: 30px;
        //       }
        //     }
        //     .product_box:hover {
        //       transform: scale(1.05);
        //       // width: 450px;
        //       // height: 300px;
        //       margin-top: -12px;
        //       color: #4fa4ff;
        //     }
        //   }
        //   .product_bottom{
        //     width: 100%;
        //     display: flex;
        //     justify-content: center;
        //     cursor: pointer;
        //   }
        // }
        .productCenter {
            // width: 100%;
            // height: 500px;
            background: #f3f4f4;
            padding: 68px 0 80px 0;
            .product_title {
                font-size: 27px;
                width: 250px;
                margin: 0px auto 40px;
                // padding-top: 30px;
                padding-bottom: 16px;
                text-align: center;
                // border-bottom: 3px solid #dfe0e1;
                border-radius: 2px;
                position: relative;
            }
            // .product_title:after {
            //   content: "";
            //   height: 3px;
            //   width: 33.3%;
            //   background-color: #2894f7;
            //   position: absolute;
            //   left: 0;
            //   bottom: -3px;
            //   border-radius: 2px;
            // }
            .product_body {
                width: 1465px;
                margin: 0 auto;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // background: #ffffff;
                // grid-template-columns: 400px 400px 400px 400px;
                // grid-gap: 30px;
                .product_box {
                    transition: 0.3s ease;
                    width: 416px;
                    margin: 0 1%;
                    // height: 300px;
                    cursor: pointer;
                    // margin-bottom: 30px;
                    background: #ffffff;
                    .imgbox {
                        // width: 396px;
                        height: 175px;
                        overflow: hidden;
                        padding-left: 20px;
                    }
                }
                img:hover {
                    transform: scale(1.2);
                    transition: all 0.3s;
                }
                .product_text {
                    padding: 0 20px;
                    height: 195px;
                    p {
                        font-size: 22px;
                        font-family: PingFang SC, PingFang SC-Medium;
                        font-weight: 500;
                        color: #0075c9;
                        margin: 32px 0 12px 0;
                    }
                    .title_detail {
                        font-size: 16px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.65);
                        line-height: 28px;
                    }
                }
                // .product_box:hover {
                //   transform: scale(1.05);
                //   // width: 450px;
                //   // height: 300px;
                //   margin-top: -12px;
                //   color: #4fa4ff;
                // }
            }
        }
        .solutionScheme {
            width: 100%;
            // height: 500px;
            // background: #fff;
            padding: 68px 0 80px 0;
            .scheme_title {
                font-size: 27px;
                width: 250px;
                margin: 30px auto 50px;
                padding-bottom: 16px;
                text-align: center;
                // border-bottom: 3px solid #dfe0e1;
                border-radius: 2px;
                position: relative;
            }
            // .scheme_title:after {
            //   content: "";
            //   height: 3px;
            //   width: 33.3%;
            //   background-color: #2894f7;
            //   position: absolute;
            //   left: 33%;
            //   bottom: -3px;
            //   border-radius: 2px;
            // }
            .scheme_body {
                // display: grid;
                // grid-template-columns: 400px 400px 400px 400px;
                // grid-gap: 30px;
                width: 1200px;
                // padding: 0 30px;
                // margin-left:60px;
                margin: 0px auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // background: #f0f8fd;
                .scheme_box {
                    // transition: 0.3s ease;
                    // width: 21%;
                    // margin: 0 1%;
                    cursor: pointer;
                    background: #f0f8fd;
                    margin-bottom: 22px;
                    width: 590px;
                    background: #f0f8fd;
                }
                .scheme_box:hover {
                    // transform: scale(1.05);
                    // width: 450px;
                    // height: 300px;
                    // margin-top: -12px;
                    color: #4fa4ff;
                }
                img {
                    width: 100%;
                }
                img:hover {
                    transform: scale(1.2);
                    transition: all 0.4s;
                }
            }
        }
        .reportNews {
            width: 100%;
            // height: 400px;
            background: #f3f4f4;
            padding: 68px 0 80px 0;
            .news_title {
                font-size: 27px;
                width: 250px;
                margin: 30px auto 50px;
                padding-bottom: 16px;
                text-align: center;
                // border-bottom: 3px solid #dfe0e1;
                border-radius: 2px;
                position: relative;
            }
            // .news_title:after {
            //   content: "";
            //   height: 3px;
            //   width: 33.3%;
            //   background-color: #2894f7;
            //   position: absolute;
            //   right: 0;
            //   bottom: -3px;
            //   border-radius: 2px;
            // }
            .news_body {
                text-align: start;
                margin: 0 100px;
                font-size: 20px;
                cursor: pointer;
                .news_box {
                    margin: 20px;
                    .text {
                        display: flex;
                        justify-content: space-between;
                    }
                    :hover {
                        color: #2894f7;
                    }
                }
                .news_bottom {
                    margin-top: 80px;
                    margin-left: 1400px;
                    height: 30px;
                }
                .news_bottom:hover {
                    color: #2894f7;
                }
            }
        }
        .customer {
            width: 100%;
            // height: 400px;
            background: #f3f4f4;
            padding: 68px 0 80px 0;
            .customer_title {
                font-size: 27px;
                width: 250px;
                margin: 30px auto 50px;
                padding-bottom: 16px;
                text-align: center;
                // border-bottom: 3px solid #dfe0e1;
                border-radius: 2px;
                position: relative;
            }
            // .customer_title:after {
            //   content: "";
            //   height: 3px;
            //   width: 33.3%;
            //   background-color: #2894f7;
            //   position: absolute;
            //   right: 0;
            //   bottom: -3px;
            //   border-radius: 2px;
            // }
            .customer_body {
                width: 1200px;
                height: 354px;
                background: #ffffff;
                margin: 0 auto;
                display: flex;
                justify-content: center;
            }
        }
    }
}
.active {
    color: #2894f7;
}
// /deep/.el-carousel__indicators--horizontal {
//   left: 83% !important;
// }
/deep/.el-carousel__indicator.is-active button {
    opacity: 1 !important;
    background-color: #2d74f4 !important;
}
/deep/.el-carousel__button {
    width: 30px !important;
    height: 5px !important;
    border-radius: 2px;
}
</style>
