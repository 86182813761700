/* eslint-disable */
import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push 防止连续点击多次路由报错
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => console.log(err))
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => console.log(err))
}

const routes = [
    {
        path: "/",
        name: "home",
        meta: { title: "莱普博乐—检测报告自动化、校准证书数字化，赋能检测校准能力提升" },
        component: () => import("../views/HomeView2.vue"),
    },

    {
        path: "/",
        name: "home",
        meta: { title: "莱普博乐—检测报告自动化、校准证书数字化，赋能检测校准能力提升" },
        component: HomeView,
        redirect: "/",
    },
    {
        path: "/product",
        name: "product",
        meta: { title: "莱普博乐-产品" },
        component: () => import("../views/Product.vue"),
        children: [],
    },
    {
        path: "/dcc",
        name: "dcc",
        meta: { title: "产品-数字校准证书" },
        component: () => import("../views/DccPage.vue"),
    },
    {
        path: "/scheme",
        name: "scheme",
        meta: { title: "方案-莱普博乐" },
        component: () => import("../views/Scheme.vue"),
    },
    {
        path: "/smartLab",
        name: "smartLab",
        meta: { title: "解决方案-解决方案" },
        component: () => import("../views/SmartLab.vue"),
    },
    {
        path: "/news",
        name: "news",
        meta: { title: "新闻-莱普博乐" },
        component: () => import("../views/News.vue"),
        children: [],
    },
    {
        path: "/news/newsDetail",
        name: "newsDetail",
        meta: { title: "新闻详情-莱普博乐" },
        component: () => import("../components/News/newsDetail.vue"),
    },
    {
        path: "/news/newsInfo",
        name: "newsInfo",
        meta: { title: "新闻-莱普博乐" },
        component: () => import("../components/News/newsInfo.vue"),
    },
    {
        path: "/CustomerCase",
        name: "CustomerCase",
        meta: { title: "客户案例-莱普博乐" },
        component: () => import("../views/CustomerCase.vue"),
    },
    {
        path: "/about",
        name: "about",
        meta: { title: "关于我们-莱普博乐" },
        component: () => import("../views/AboutUs.vue"),
    },
    {
        path: "/requestPresentation",
        name: "requestPresentation",
        meta: { title: "请求演示-莱普博乐" },
        component: () => import("../views/RequestPresentation.vue"),
    },
    // {
    //     path: "/demonstrate",
    //     name: "demonstrate",
    //     meta: { title: "请求演示-莱普博乐" }

    // },
    // {
    //   path: "/about",
    //   name: "about",
    //   component: () => import("../views/AboutUs.vue")
    // },
    {
        path: "/test",
        name: "test",
        meta: { title: "测试-莱普博乐" },
        component: () => import("../views/test.vue"),
    },
    {
        path: "*",
        name: "notfount",
        component: () => import("../components/404/notfound.vue"),
    },
]

const router = new VueRouter({
    routes,
    mode: "hash",
})

// 全局前置路由守卫
router.beforeEach((to, from, next) => {
    console.log("前置路由守卫", to, from)
    window.document.title = to.meta.title == undefined ? "莱普博乐" : to.meta.title
    let localId = localStorage.getItem("titleId")
    // console.log("localId", localId)
    next()
})

//全局后置路由守卫
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})
export default router
